<template>
  <div id="service-edit-tab-info">
    <!-- Content Row -->

    <!-- Col Header -->
    <div class="flex items-end">
      <feather-icon class="mr-2" icon="ServiceIcon" svgClasses="w-5 h-5"/>
      <span class="leading-none font-medium">Service Uris</span>
    </div>

    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <vs-input class="w-full mt-4" label="Service Uri Callback" v-model="data_local.uris.oauth"/>
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <vs-input class="w-full mt-4" label="Service Landing Uri" v-model="data_local.uris.landing"/>
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <vs-input class="w-full mt-4" label="Service Webhook Uri" v-model="data_local.uris.webhook"/>
      </div>
    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button :disabled="!validateForm" @click="initiateServiceUpdate" class="ml-auto mt-2">
            Save Changes
          </vs-button>
          <vs-button @click="resetData" class="ml-4 mt-2" color="warning" type="border">
            Reset
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
  components: {
    vSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data_local: JSON.parse(JSON.stringify(this.data)),
      services: [],
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  methods: {
    initiateServiceUpdate() {

      this.$vs.loading();

      this.$http.post(`services/${this.data_local.id}`, {uris: this.data_local.uris})
        .then(response => {
          this.$vs.loading.close();

          if (response.data && response.data.data) {

            this.data_local = response.data.data;

            return this.$vs.notify({
              title: 'Success',
              text: `${this.data_local.name}'s settings have been updated`,
              iconPack: 'feather',
              icon: 'icon-service-check',
              color: 'success',
            });

          }

        })
        .catch(exception => {
          this.$vs.loading.close();



          let response;
          if (exception.response) {
            response = exception.response;
          }

          return this.$vs.notify({
            title: 'Failed to update',
            text: (response || {message: 'An unknown error has occurred'}).message,
            iconPack: 'feather',
            icon: 'icon-service-x',
            color: 'danger',
          });
        });
    },
    resetData() {
      this.data_local = JSON.parse(JSON.stringify(this.data));
    },
  },
};
</script>

<style>
.avatar {
  width: 15rem;
  height: 15rem;
}
</style>
