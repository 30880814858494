<!-- =========================================================================================
  File Name: ServiceEdit.vue
  Description: Service Edit Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/service/pixinvent
========================================================================================== -->

<template>
  <div id="page-service-edit">

    <vs-alert :active.sync="service_not_found" color="danger" title="Service Not Found">
      <span>Service record with id: {{ $route.params.serviceId }} not found. </span>
      <span>
        <span>Check </span>
        <router-link class="text-inherit underline" to="/a/services">All Services</router-link>
      </span>
    </vs-alert>

    <vx-card v-if="service_data">

      <div class="tabs-container px-6 pt-6" slot="no-body">

        <vs-tabs class="tab-action-btn-fill-conatiner" v-model="activeTab">
          <vs-tab icon="icon-settings" icon-pack="feather" label="General">
            <div class="tab-text">
              <service-edit-tab-general :data="service_data" class="mt-4"/>
            </div>
          </vs-tab>
          <vs-tab icon="icon-image" icon-pack="feather" label="Images">
            <div class="tab-text">
              <service-edit-tab-images :data="service_data" class="mt-4"/>
            </div>
          </vs-tab>
          <vs-tab icon="icon-shield" icon-pack="feather" label="Uris">
            <div class="tab-text">
              <service-edit-tab-uris :data="service_data" class="mt-4"/>
            </div>
          </vs-tab>
        </vs-tabs>

      </div>

    </vx-card>
  </div>
</template>

<script>
import ServiceEditTabGeneral from './ServiceEditTabGeneral.vue';
import ServiceEditTabImages from './ServiceEditTabImages.vue';
import ServiceEditTabUris from './ServiceEditTabUris.vue';

// Store Module

export default {
  components: {
    ServiceEditTabGeneral,
    ServiceEditTabImages,
    ServiceEditTabUris,
  },
  data() {
    return {
      service_data: null,
      service_not_found: false,
      activeTab: 0,
    };
  },
  watch: {
    activeTab() {
      this.fetchServiceData(this.$route.params.serviceId);
    },
  },
  methods: {
    fetchServiceData(serviceId) {

      this.$http.get(`services/${serviceId}`)
        .then(response => {

          if (response.data.data) {
            this.service_data = response.data.data;
          }

        })
        .catch(error => {

          if (error.response.status === 404) {
            this.service_not_found = true;
            return;
          }
          console.error(error);
        });
    },
  },
  created() {
    this.fetchServiceData(this.$route.params.serviceId);
  },
};

</script>
